<template>
  <div>
    <button @click="toggleNav">
      {{ showNav ? "关闭导航栏" : "显示导航栏" }}
    </button>
    <nav v-if="showNav">
      <ul>
        <li
          v-for="(menu, index) in menus"
          :key="index"
          @click="navigate(menu.route)"
        >
          <span @click="toggleSubMenu(index)"> {{ menu.title }} </span>
          <ul v-if="menu.showSubMenus">
            <li
              v-for="(subMenu, subIndex) in menu.subMenus"
              :key="subIndex"
              @click="navigate(subMenu.route)"
            >
              {{ subMenu.title }}
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showNav: false,
      menus: [
        {
          title: "一级菜单1",
          showSubMenus: false,
          subMenus: [
            { title: "子菜单1", route: "/submenu1" },
            { title: "子菜单2", route: "/submenu2" },
            { title: "子菜单3", route: "/submenu3" },
          ],
        },
        {
          title: "一级菜单2",
          showSubMenus: false,
          subMenus: [
            { title: "子菜单4", route: "/submenu4" },
            { title: "子菜单5", route: "/submenu5" },
            { title: "子菜单6", route: "/submenu6" },
          ],
        },
        {
          title: "一级菜单3",
          route: "/menu3",
        },
      ],
    };
  },
  methods: {
    toggleNav() {
      this.showNav = !this.showNav;
    },
    navigate(route) {
      this.$router.push(route);
    },
    toggleSubMenu(index) {
      this.menus[index].showSubMenus = !this.menus[index].showSubMenus;
    },
  },
};
</script>
